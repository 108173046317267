.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/**Profile Header**/
.fb-profile img.fb-image-lg {
  z-index: 0;
  width: 100%;
  margin-bottom: 10px;
}

.fb-image-profile {
  margin: -155px 0px 0px 50px;
  z-index: 9;
  width: 15%;
  border-radius: 50%;
  border: 2px solid #4f0259;
  background-color: #4f0259;
  color: white;
  font-size: 8rem;
}

.fb-profile-text {
  text-align: left;
}

@media (max-width: 768px) {
  .bookmarkNav > li{ 
  padding: 12px;
  }
  .headerBody {
    margin-top: 20px !important;
  }
  .hs {
    text-align: left !important;
  }
  .headerStats,
  .headerStats > span {
    font-size: 10px !important;
  }
  .fb-profile-text > h1 {
    font-weight: 700;
    font-size: 16px;
  }

  .fb-image-profile {
    margin: -65px 10px 0px 25px;
    z-index: 9;
    width: 20%;
    border-radius: 50%;
    border: 2px solid #4f0259;
    background-color: #4f0259;
    color: white;
    font-size: 3rem;
  }
  .bookmarkNav{
    display: -webkit-inline-box !important;
  }
}

/**Profile Settings Navigation**/
.profileHeader .nav-link.active {
  color: #4f0259;
  border-left: solid !important;
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  border-right: unset;
  border-bottom: 1px solid #dee2e6;
}
.profileHeader .nav-link {
  color: #cab3cd;
  border-bottom: 1px solid #dee2e6;
  font-weight: 600;
  border-right: unset;
  border-left: unset;
  text-align: left;
}

.profileHeader .nav-link:hover {
  color: #636363;
  border-right: unset;
  border-left: unset;
}

/*** Profile Theme Colors ***/

.TFList:hover {
  background-color: #a6c;
  color: white;
  cursor: pointer;
}
.TFList {
  color: black;
}

.notifSettings {
  float: right;
}

.TFList:hover .icon-fa {
  color: white !important;
}

/********* Payment ****/
.walletCardTitle > .card-title {
  margin-bottom: unset;
}
.walletCardTitle > .card-text {
  margin-bottom: unset;
}

/****Profile Banner Edit section ******/

.profile-header {
  opacity: 1;
}
.profile-image-upload {
  transition: 0.5s ease;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.profile-header:hover bannerimg {
  opacity: 0.3;
}

.profile-header:hover .profile-image-upload {
  background-image: url("./assets//images/cam.png");
  background-size: cover;
  height: 150px;
  width: 150px;
  cursor: pointer;
}

/****Profile display image Edit section ******/

.profile-display {
  opacity: 1;
}
.profileDisplayBody{
  transition: 0.5s ease;
  position: relative;
  top: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.profile-display:hover .profileimg {
  opacity: 0.3;
}
.profile-display:hover .profile-display-image-upload {
  background-image: url("./assets//images/cam.png");
  transform: translate(50%, 50%);
  background-size: cover;
  height: 80px;
  width: 80px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .headerComponent {
    padding-right: unset !important;
    padding-left: unset !important;
  }
  .header {
    margin-top: unset !important;
  }
  .sidebar-menu {
    width: 200px;
    height: 46%;
    position: absolute;
    top: 12%;
    left: -300px;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition: 0.3s ease all;
    transition: 0.3s ease all;
    background: #333;
  }
  .open {
    -webkit-transform: translateX(300px);
    transform: translateX(300px);
    z-index: 1;
  }
  .bookmarkSidebar{
    display: flex !important;
  }
}

a, a:visited {
  color: #4f0259;
}