/**Header**/
.faq-header img.faq-header-image-lg {
  z-index: 0;
  width: 100%;
  margin-bottom: 10px;
}
.faq-header-area {
  position: absolute;
  top: 30%;
  left: 35%;
  color: white;
}

/* Enable Search positioning */
.inner-addon {
  position: relative;
}

/* style glyph */
.inner-addon .glyphicon {
  position: absolute;
  padding: 10px;
  pointer-events: none;
  color: #4f0259;
}

/* align glyph */
.right-addon .glyphicon {
  right: 5px;
  top: -5px;
}

.searchBar {
  width: 400px !important;
}

@media (max-width: 768px) {
  /**Header**/
  .faq-header-area {
    position: relative;
    top: 0%;
    transform: translate(0%, -50%);
    left: 0%;
    color: white;
  }

  /* Enable Search positioning */
  .inner-addon {
    position: relative;
  }

  /* style glyph */
  .inner-addon .glyphicon {
    position: absolute;
    padding: 10px;
    pointer-events: none;
    color: #4f0259;
  }

  /* align glyph */
  .right-addon .glyphicon {
    right: 5px;
    top: -5px;
  }

  .searchBar {
    width: 100% !important;
  }

  .faq-header-image-lg {
    height: 150px !important;
  }

  .faq-header-area > .form-inline {
    display: inline-table !important;
  }
  .accordion {
    margin-top: 1em;
  }
}

/**Settings Navigation**/
.faqSidebar .nav-link.active {
  color: #4f0259;
  border-left: solid !important;
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  border-right: unset;
  border-bottom: 1px solid #dee2e6;
}
.faqSidebar .nav-link {
  color: #cab3cd;
  border-bottom: 1px solid #dee2e6;
  font-weight: 600;
  border-right: unset;
  border-left: unset;
  text-align: left;
}

.faqSidebar .nav-link:hover {
  color: #636363;
  border-right: unset;
  border-left: unset;
}

/***   Theme Colors ***/

.TFList:hover {
  background-color: #a6c;
  color: white;
  cursor: pointer;
}

.notifSettings {
  float: right;
}
