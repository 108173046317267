.bold-heading {
  font-weight: 500;
}
/** Following list tabs*/
.followingListTab {
  border: 1px solid #dee2e6;
  border-radius: 1em;
  margin-right: 1em;
  color: black;
}
.nav-followingList {
  border-bottom: none;
}
.nav-link-followingList {
  border: none !important;
  color: #cab3cd;
  font-weight: 600;
}

.nav-link-followingList:hover {
  color: black;
}
.followingListTab:hover {
  border: 1px solid #dee2e6;
  border-radius: 1em;
  color: black;
}
.nav-followingList .nav-link-followingList.active {
  color: #4f0259;
  border-radius: 1em;
}
