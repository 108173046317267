
/****** Notification *****/
.notifications>.nav-link{
  padding-right: 4.5em;
  padding-left: 4.5em;
}


.notifications .nav-link.active {
  color: #4f0259;
  border-top: unset;
  border-bottom: unset;
}
.notifications .nav-link {
  color: #cab3cd;
  border-right: solid !important;
  font-weight: 600;
  border-left: unset;
  border-top: unset;
  border-bottom: unset;
  text-align: left;
}

.notifications .nav-link:hover {
  color: #636363;
  border-right: unset;
    border-left: unset;
}

.notifications{
border-bottom: unset;
}
#notification-tab-tab-tipped{
  border-right: unset !important;
}
@media (max-width: 768px) {
  .nav{
    display: inline-block
  }
.notifications>.nav-link{
  display: inline-block !important;
}
.notifications>.nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
}