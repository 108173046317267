.prev,
.next {
  background: none;
  border: none;
  color: #4f0259;
  cursor: pointer;
}

.prev.disabled,
.next.disabled {
  pointer-events: none;
  box-shadow: none;
  color: #999;
}

.paginationItem {
  border: none;
  color: white;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background: #4f0259;
  margin-left: 5px;
  margin-right: 5px;
}

.paginationItem.active {
  border: 2px solid #FCA8FE;
  color: #FCA8FE;
  height: 45px;
  width: 45px;
  pointer-events: none;
}