
/**Settings Navigation**/
.statistics .nav-link.active {
  color: #4f0259;
  border-left: solid !important;
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  border-right: unset;
  border-bottom: 1px solid #dee2e6;
}
.statistics .nav-link {
  color: #cab3cd;
  border-bottom: 1px solid #dee2e6;
  font-weight: 600;
  border-right: unset;
  border-left: unset;
  text-align: left;
}

.statistics .nav-link:hover {
  color: #636363;
  border-right: unset;
    border-left: unset;
}
@media (max-width:768px)
{
  .statistics>.nav-item {
    display: inline-block;
  }
  .statTab>.tab-pane{
    padding-right: 0;
    padding-left: 0
  }
  .statTable{
    padding-right: 0;
    padding-left: 0
  }
}