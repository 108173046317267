
/****** User Page *****/
.userpage .nav-link.active {
  color: #4f0259;
  border-top: unset;
  border-bottom: unset;
}
.userpage .nav-link {
  color: #cab3cd;
  font-weight: 600;
  border-left: unset;
  border-top: unset;
  border-bottom: unset;
  text-align: center;
}

.userpage .nav-link:hover {
  color: #636363;
  border-right: unset;
  border-left: unset;
}

.userpage{
border-bottom: unset;
}
#userpage-tab-tab-tipped{
  border-right: unset !important;
}