.aws-btn {
  --slider-height-percentage: 100%;
  --slider-transition-duration: 500ms;
  --organic-arrow-thickness: 9px;
  --organic-arrow-border-radius: 0px;
  --organic-arrow-height: 60px;
  --organic-arrow-color: #4f0259;
  --control-button-width: 10%;
  --control-button-height: 25%;
  --control-button-background: transparent;
  --control-bullet-color: #4f0259;
  --control-bullet-active-color: #4f0259;
  --loader-bar-color: #4f0259;
  --loader-bar-height: 6px;
  }